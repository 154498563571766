var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "payment-ssl-badge" }, [
    _c(
      "svg",
      {
        attrs: {
          width: "98",
          height: "49",
          viewBox: "0 0 98 49",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      [
        _c("g", { attrs: { "clip-path": "url(#clip0_417_2683)" } }, [
          _c("path", {
            attrs: {
              d: "M97.9988 17.6939V38.1707C97.9988 39.6378 96.8084 40.8294 95.3402 40.8294H26.7783C25.3112 40.8294 24.1196 39.6389 24.1196 \n      38.1707V17.6939C24.1196 16.2235 25.3101 15.0353 26.7783 15.0353H95.3413C96.8084 15.0353 97.9999 16.2235 97.9999 17.6939H97.9988Z",
              fill: "url(#paint0_linear_417_2683)",
            },
          }),
          _c("path", {
            attrs: {
              d: "M97.9988 31.2034V38.1708C97.9988 39.6379 96.8084 40.8294 95.3402 40.8294H26.7783C25.3112 \n        40.8294 24.1196 39.639 24.1196 38.1708V31.2034H97.9988Z",
              fill: "#4D4D4D",
            },
          }),
          _c("path", {
            attrs: {
              d: "M42.2574 11.2612C42.0921 10.1501 41.1243 9.35315 40.0011 9.39835C34.352 9.62541 27.6635 9.40055 \n      22.9954 1.57447C22.5975 0.9076 21.924 0.561489 21.2417 0.535034C20.5583 0.561489 19.8859 0.9076 19.488 \n      1.57447C14.8199 9.40165 8.13028 9.62541 2.48119 9.39835C1.35798 9.35315 0.390195 10.1501 0.224855 \n      11.2612C-0.598535 16.7857 0.929201 25.2467 3.2682 31.297C6.45154 39.5332 11.8879 45.7003 21.2053 \n      48.844C21.2175 48.8484 21.2648 48.8484 21.277 48.844C30.5933 45.7003 36.0297 39.5332 39.2141 31.297C41.5531 \n      25.2467 43.0819 16.7857 42.2574 11.2612Z",
              fill: "url(#paint1_linear_417_2683)",
            },
          }),
          _c("path", {
            attrs: {
              d: "M21.2415 46.3573L21.139 46.3198C13.5599 43.5388 8.58204 38.4948 5.47035 30.4461C3.14127 24.4222 \n      1.92107 16.8508 2.50196 12.035L2.53503 11.7616L2.80949 11.7715C3.4951 11.7958 4.10024 11.8079 4.65798 \n      11.8079C6.86802 11.8079 10.1792 11.6459 13.4066 10.2405C16.4191 8.92879 18.9709 6.70111 20.9924 3.61918L21.2426 \n      3.23779L21.4928 3.61918C23.5144 6.70221 26.0661 8.92879 29.0786 10.2405C32.306 11.6459 35.6172 11.8079 37.8273 \n      11.8079C38.3872 11.8079 38.9924 11.7958 39.6758 11.7715L39.9502 11.7616L39.9833 12.035C40.5653 16.8519 39.344 \n      24.4222 37.0149 30.4461C33.9043 38.4926 28.9254 43.5366 21.3462 46.3198L21.2437 46.3573H21.2415ZM3.06521 \n      12.3789C2.58132 17.0536 3.81034 24.495 6.0281 30.2301C9.05822 38.0694 13.8938 42.9943 21.2415 45.7191C28.5903 \n      42.991 33.4259 38.0661 36.4561 30.229C38.6738 24.4939 39.9017 17.0525 39.4189 12.3778C38.8403 12.3954 38.3167 \n      12.4042 37.8262 12.4042C35.5632 12.4042 32.1683 12.2367 28.8394 10.7872C25.8424 9.48212 23.2884 7.30735 21.2426 \n      4.31911C19.1968 7.30735 16.6429 9.48212 13.6458 10.7872C10.3159 12.2367 6.92203 12.4042 4.65909 12.4042C4.17078 \n      12.4042 3.64721 12.3954 3.06632 12.3778L3.06521 12.3789Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              d: "M14.3811 22.7908H16.1701V19.8081C16.1701 17.1726 18.3063 15.0364 20.9418 15.0364H21.5381C24.1736 15.0364 \n      26.3098 17.1726 26.3098 19.8081V22.7908H28.0988V32.3353H14.3789V22.7908H14.3811ZM20.7897 27.4391L20.3466 \n      30.5474H22.1355L21.6913 27.438C22.3802 27.1569 22.6635 26.3059 22.2215 25.6523C21.9647 25.2731 21.4918 25.0736 \n      21.041 25.1563C20.2639 25.2974 19.8549 26.0954 20.136 26.7843C20.2573 27.0808 20.4932 27.3167 20.7897 27.438V27.4391ZM24.5219 \n      20.1057C24.5219 17.9541 22.4508 16.2863 20.1933 16.9885C18.8486 17.4073 17.9635 18.6959 17.9635 20.1057V22.7897H24.5219V20.1057Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              d: "M45.4275 20.3406C46.2256 20.3406 46.6356 20.8179 46.6356 21.6534V21.8176H45.8596V21.6016C45.8596 21.229 45.7108 \n      21.0868 45.4496 21.0868C45.1883 21.0868 45.0395 21.229 45.0395 21.6016C45.0395 22.6763 46.6433 22.8769 46.6433 \n      24.3694C46.6433 25.2049 46.2256 25.6822 45.4198 25.6822C44.614 25.6822 44.1963 25.2049 44.1963 24.3694V24.0486H44.9723V24.4212C44.9723 \n      24.7937 45.1365 24.9282 45.3978 24.9282C45.659 24.9282 45.8232 24.7937 45.8232 24.4212C45.8232 23.3465 44.2194 23.1458 \n      44.2194 21.6534C44.2194 20.8179 44.6295 20.3406 45.4275 20.3406Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              d: "M47.9329 22.6012H49.0594V23.3475H47.9329V24.8763H49.3504V25.6226H47.1128V20.4011H49.3504V21.1474H47.9329V22.6023V22.6012Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              d: "M52.2376 23.6749V24.3683C52.2376 25.2038 51.8198 25.6811 51.014 25.6811C50.2083 25.6811 49.7905 25.2038 49.7905 \n      24.3683V21.6534C49.7905 20.8179 50.2083 20.3406 51.014 20.3406C51.8198 20.3406 52.2376 20.8179 52.2376 \n      21.6534V22.1604H51.4616V21.6005C51.4616 21.2279 51.2973 21.0857 51.0361 21.0857C50.7749 21.0857 50.6106 \n      21.2279 50.6106 21.6005V24.4201C50.6106 24.7926 50.7749 24.9271 51.0361 24.9271C51.2973 24.9271 51.4616 \n      24.7926 51.4616 24.4201V23.6738H52.2376V23.6749Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              d: "M53.5193 20.4V24.4277C53.5193 24.8003 53.6835 24.9347 53.9448 24.9347C54.206 24.9347 54.3702 24.8003 \n      54.3702 24.4277V20.4H55.1462V24.3759C55.1462 25.2114 54.7285 25.6887 53.9227 25.6887C53.117 25.6887 52.6992 25.2114 \n      52.6992 24.3759V20.4H53.5193Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              d: "M57.4202 25.6225C57.375 25.4881 57.3452 25.4065 57.3452 24.981V24.1609C57.3452 23.6759 57.181 23.4974 \n      56.8084 23.4974H56.5252V25.6236H55.7051V20.4022H56.9429C57.7928 20.4022 58.1587 20.7979 58.1587 21.6026V22.0126C58.1587 \n      22.5494 57.9868 22.9 57.6219 23.0719C58.032 23.2428 58.1664 23.6385 58.1664 24.183V24.9887C58.1664 25.2423 58.1741 25.4285 \n      58.2557 25.6225H57.4202ZM56.5252 21.1463V22.75H56.8459C57.1523 22.75 57.3386 22.6156 57.3386 22.1978V21.6831C57.3386 \n      21.3105 57.2119 21.1463 56.9209 21.1463H56.5252Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              d: "M59.5232 22.6012H60.6497V23.3475H59.5232V24.8763H60.9407V25.6226H58.7031V20.4011H60.9407V21.1474H59.5232V22.6023V22.6012Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              d: "M62.3647 20.4H64.9011V21.1463H64.0435V25.6226H63.2234V21.1463H62.3658V20.4H62.3647Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              d: "M66.9744 25.6225C66.9292 25.4881 66.8994 25.4065 66.8994 24.981V24.1609C66.8994 23.6759 66.7352 \n      23.4974 66.3626 23.4974H66.0794V25.6236H65.2593V20.4022H66.4971C67.347 20.4022 67.7129 20.7979 67.7129 \n      21.6026V22.0126C67.7129 22.5494 67.541 22.9 67.1761 23.0719C67.5862 23.2428 67.7206 23.6385 67.7206 \n      24.183V24.9887C67.7206 25.2423 67.7283 25.4285 67.8099 25.6225H66.9744ZM66.0794 21.1463V22.75H66.4001C66.7066 \n      22.75 66.8928 22.6156 66.8928 22.1978V21.6831C66.8928 21.3105 66.7661 21.1463 66.4751 21.1463H66.0794Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              d: "M70.913 25.6226H70.0852L69.943 24.6746H68.9356L68.7934 25.6226H68.0405L68.876 20.4011H70.0764L70.9119 \n      25.6226H70.913ZM69.0403 23.967H69.8306L69.4349 21.3259L69.0392 23.967H69.0403Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              d: "M72.0242 21.8396V25.6215H71.2856V20.4H72.3152L73.1584 23.5249V20.4H73.8892V25.6215H73.046L72.0242 21.8396Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              d: "M75.5808 20.3405C76.3789 20.3405 76.7889 20.8177 76.7889 21.6533V21.8175H76.0129V21.6014C76.0129 \n      21.2289 75.8641 21.0867 75.6029 21.0867C75.3416 21.0867 75.1928 21.2289 75.1928 21.6014C75.1928 22.6762 \n      76.7966 22.8768 76.7966 24.3692C76.7966 25.2047 76.3789 25.682 75.5731 25.682C74.7674 25.682 74.3496 \n      25.2047 74.3496 24.3692V24.0485H75.1256V24.421C75.1256 24.7936 75.2898 24.9281 75.5511 24.9281C75.8123 \n      24.9281 75.9766 24.7936 75.9766 24.421C75.9766 23.3463 74.3728 23.1457 74.3728 21.6533C74.3728 20.8177 \n      74.7828 20.3405 75.5808 20.3405Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              d: "M79.9228 25.6226H79.095L78.9528 24.6746H77.9453L77.8031 25.6226H77.0503L77.8858 20.4011H79.0873L79.9228 \n      25.6226ZM78.05 23.967H78.8404L78.4458 21.3259L78.05 23.967Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              d: "M82.6824 23.6749V24.3683C82.6824 25.2038 82.2646 25.6811 81.4589 25.6811C80.6531 25.6811 80.2354 \n      25.2038 80.2354 24.3683V21.6534C80.2354 20.8179 80.6531 20.3406 81.4589 20.3406C82.2646 20.3406 82.6824 20.8179 \n      82.6824 21.6534V22.1604H81.9064V21.6005C81.9064 21.2279 81.7422 21.0857 81.4809 21.0857C81.2197 21.0857 81.0554 \n      21.2279 81.0554 21.6005V24.4201C81.0554 24.7926 81.2197 24.9271 81.4809 24.9271C81.7422 24.9271 81.9064 24.7926 \n      81.9064 24.4201V23.6738H82.6824V23.6749Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              d: "M82.9429 20.4H85.4792V21.1463H84.6205V25.6226H83.8004V21.1463H82.9429V20.4Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              d: "M85.8364 20.4H86.6576V25.6215H85.8364V20.4Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              d: "M87.187 21.6534C87.187 20.8179 87.6268 20.3406 88.4326 20.3406C89.2383 20.3406 89.6781 20.8179 89.6781 \n      21.6534V24.3683C89.6781 25.2038 89.2383 25.6811 88.4326 25.6811C87.6268 25.6811 87.187 25.2038 87.187 24.3683V21.6534ZM88.0071 \n      24.4212C88.0071 24.7937 88.1713 24.9359 88.4326 24.9359C88.6938 24.9359 88.858 24.7937 88.858 24.4212V21.6016C88.858 \n      21.229 88.6938 21.0868 88.4326 21.0868C88.1713 21.0868 88.0071 21.229 88.0071 21.6016V24.4212Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              d: "M90.9377 21.8396V25.6215H90.1992V20.4H91.2287L92.0709 23.5249V20.4H92.8017V25.6215H91.9595L90.9377 21.8396Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              d: "M50.564 38.2436C50.3612 38.0187 50.2598 37.6957 50.2598 37.2747V36.9782H51.0325V37.3342C51.0325 37.6704 \n      51.1735 37.839 51.4557 37.839C51.5946 37.839 51.6993 37.7982 51.771 37.7167C51.8426 37.6351 51.879 37.5028 51.879 \n      37.3188C51.879 37.1016 51.8294 36.9098 51.7302 36.7434C51.631 36.578 51.448 36.3785 51.1813 36.1459C50.8451 35.8494 \n      50.6092 35.5805 50.4758 35.3402C50.3424 35.0999 50.2752 34.8287 50.2752 34.5267C50.2752 34.1156 50.3788 33.7981 \n      50.5871 33.5722C50.7955 33.3473 51.0964 33.2338 51.4932 33.2338C51.89 33.2338 52.1799 33.3462 52.3805 33.5722C52.5811 \n      33.797 52.6814 34.12 52.6814 34.5411V34.756H51.9088V34.4881C51.9088 34.3096 51.8746 34.1795 51.8051 34.0979C51.7357 \n      34.0164 51.6343 33.9756 51.5009 33.9756C51.2287 33.9756 51.092 34.1409 51.092 34.4727C51.092 34.6612 51.1427 34.8365 \n      51.2441 34.9996C51.3455 35.1627 51.5296 35.3611 51.7974 35.5937C52.1391 35.8902 52.3739 36.1603 52.5029 36.4028C52.6318 \n      36.6453 52.6958 36.9297 52.6958 37.257C52.6958 37.6825 52.5899 38.0099 52.3805 38.2369C52.17 38.4651 51.8647 38.5786 \n      51.4634 38.5786C51.0622 38.5786 50.7679 38.4662 50.5651 38.2403V38.2436H50.564Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              d: "M53.3262 38.2436C53.1234 38.0187 53.022 37.6957 53.022 37.2747V36.9782H53.7947V37.3342C53.7947 \n      37.6704 53.9357 37.839 54.2179 37.839C54.3568 37.839 54.4615 37.7982 54.5332 37.7167C54.6048 37.6351 54.6412 \n      37.5028 54.6412 37.3188C54.6412 37.1016 54.5916 36.9098 54.4924 36.7434C54.3932 36.578 54.2102 36.3785 53.9435 \n      36.1459C53.6073 35.8494 53.3714 35.5805 53.238 35.3402C53.1046 35.0999 53.0374 34.8287 53.0374 34.5267C53.0374 \n      34.1156 53.141 33.7981 53.3493 33.5722C53.5577 33.3473 53.8586 33.2338 54.2554 33.2338C54.6522 33.2338 54.9421 \n      33.3462 55.1427 33.5722C55.3433 33.797 55.4436 34.12 55.4436 34.5411V34.756H54.671V34.4881C54.671 34.3096 54.6368 \n      34.1795 54.5673 34.0979C54.4979 34.0164 54.3965 33.9756 54.2631 33.9756C53.9909 33.9756 53.8542 34.1409 53.8542 \n      34.4727C53.8542 34.6612 53.9049 34.8365 54.0063 34.9996C54.1077 35.1627 54.2918 35.3611 54.5596 35.5937C54.9013 \n      35.8902 55.1361 36.1603 55.2651 36.4028C55.394 36.6453 55.458 36.9297 55.458 37.257C55.458 37.6825 55.3522 38.0099 \n      55.1427 38.2369C54.9322 38.4651 54.6269 38.5786 54.2256 38.5786C53.8244 38.5786 53.5301 38.4662 53.3273 \n      38.2403V38.2436H53.3262Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              d: "M55.9253 33.3098H56.7421V37.7652H58.0857V38.5081H55.9253V33.3109V33.3098Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              d: "M59.6675 33.3098H61.8952V34.0527H60.4843V35.4262H61.6053V36.1691H60.4843V37.7652H61.8952V38.5081H59.6675V33.3109V33.3098Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              d: "M62.3628 33.3097H63.3879L64.1826 36.4214H64.197V33.3097H64.9245V38.5069H64.0856L63.1057 34.7129H63.0903V38.5069H62.3628V33.3097Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              d: "M65.7896 38.248C65.5846 38.0253 65.481 37.7112 65.481 37.3044V34.5124C65.481 34.1068 65.5835 33.7915 65.7896 \n      33.5688C65.9946 33.3462 66.2933 33.2349 66.6846 33.2349C67.0759 33.2349 67.3735 33.3462 67.5797 33.5688C67.7847 33.7915 \n      67.8883 34.1057 67.8883 34.5124V35.0613H67.1156V34.4595C67.1156 34.1376 66.9789 33.9767 66.7078 33.9767C66.4366 33.9767 \n      66.2988 34.1376 66.2988 34.4595V37.3628C66.2988 37.6803 66.4355 37.8379 66.7078 37.8379C66.98 37.8379 67.1156 37.6792 67.1156 \n      37.3628V36.5681H67.8883V37.3033C67.8883 37.709 67.7858 38.0242 67.5797 38.2469C67.3746 38.4695 67.0759 38.5808 66.6846 \n      38.5808C66.2933 38.5808 65.9957 38.4695 65.7896 38.2469V38.248Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              d: "M68.3765 33.3098H69.5867C70.0078 33.3098 70.3142 33.4079 70.5071 33.603C70.7 33.7981 70.797 34.099 70.797 \n      34.5047V34.8243C70.797 35.3644 70.6185 35.705 70.2624 35.8494V35.8638C70.4608 35.9233 70.6008 36.0445 70.6824 \n      36.2275C70.764 36.4105 70.8047 36.6552 70.8047 36.9627V37.8765C70.8047 38.0253 70.8092 38.1455 70.8202 38.2369C70.8301 \n      38.3284 70.8543 38.4188 70.894 38.5081H70.0629C70.0332 38.4243 70.0133 38.345 70.0034 38.27C69.9935 38.1962 69.9891 \n      38.0628 69.9891 37.8688V36.9186C69.9891 36.6816 69.9505 36.5152 69.8744 36.4215C69.7973 36.3278 69.665 36.2804 69.4776 \n      36.2804H69.1954V38.5081H68.3787V33.3109H68.3765V33.3098ZM69.4897 35.5375C69.6529 35.5375 69.7752 35.4956 69.8568 \n      35.4118C69.9384 35.3281 69.9792 35.187 69.9792 34.9886V34.5873C69.9792 34.3989 69.9461 34.2633 69.8788 34.1795C69.8116 \n      34.0957 69.7069 34.0527 69.5636 34.0527H69.1921V35.5375H69.4897Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              d: "M72.0008 36.2947L71.0132 33.3098H71.8818L72.4384 35.2178H72.4538L73.0105 33.3098H73.8052L72.8176 36.2947V38.5081H72.0008V36.2947Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              d: "M74.1758 33.3098H75.3783C75.784 33.3098 76.0882 33.4189 76.2921 33.6372C76.4949 33.8554 76.5964 34.174 76.5964 \n      34.5951V35.1076C76.5964 35.5287 76.4949 35.8472 76.2921 36.0655C76.0893 36.2837 75.7851 36.3917 75.3783 \n      36.3917H74.9926V38.5081H74.1758V33.3109V33.3098ZM75.3783 35.6488C75.5117 35.6488 75.612 35.6113 75.6793 \n      35.5375C75.7465 35.4636 75.7796 35.3369 75.7796 35.1583V34.5421C75.7796 34.3636 75.7465 34.2379 75.6793 \n      34.163C75.612 34.088 75.5117 34.0516 75.3783 34.0516H74.9926V35.6477H75.3783V35.6488Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              d: "M77.6951 34.0527H76.8408V33.3098H79.3661V34.0527H78.5119V38.5081H77.6951V34.0527Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              d: "M79.7598 33.3098H80.5765V38.507H79.7598V33.3098Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              d: "M81.4451 38.2402C81.2324 38.0121 81.1255 37.6902 81.1255 37.2747V34.5421C81.1255 34.1266 81.2324 33.8047 \n      81.4451 33.5766C81.6579 33.3484 81.9654 33.2349 82.3655 33.2349C82.7657 33.2349 83.0732 33.3484 83.2859 \n      33.5766C83.4987 33.8047 83.6056 34.1255 83.6056 34.5421V37.2747C83.6056 37.6902 83.4987 38.0121 83.2859 \n      38.2402C83.0732 38.4684 82.7657 38.582 82.3655 38.582C81.9654 38.582 81.6579 38.4684 81.4451 38.2402ZM82.7888 \n      37.3265V34.4903C82.7888 34.1486 82.6477 33.9778 82.3655 33.9778C82.0834 33.9778 81.9423 34.1486 81.9423 \n      34.4903V37.3265C81.9423 37.6682 82.0834 37.839 82.3655 37.839C82.6477 37.839 82.7888 37.6682 82.7888 37.3265Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              d: "M84.1558 33.3098H85.1809L85.9756 36.4215H85.9899V33.3098H86.7174V38.507H85.8786L84.8987 34.713H84.8844V38.507H84.1569V33.3098H84.1558Z",
              fill: "white",
            },
          }),
        ]),
        _c(
          "defs",
          [
            _c(
              "linearGradient",
              {
                attrs: {
                  id: "paint0_linear_417_2683",
                  x1: "61.0598",
                  y1: "17.4018",
                  x2: "61.0598",
                  y2: "37.5049",
                  gradientUnits: "userSpaceOnUse",
                },
              },
              [
                _c("stop", { attrs: { "stop-color": "#A6DC48" } }),
                _c("stop", { attrs: { offset: "1", "stop-color": "#14A40E" } }),
              ],
              1
            ),
            _c(
              "linearGradient",
              {
                attrs: {
                  id: "paint1_linear_417_2683",
                  x1: "21.2417",
                  y1: "3.33148",
                  x2: "21.2417",
                  y2: "45.815",
                  gradientUnits: "userSpaceOnUse",
                },
              },
              [
                _c("stop", { attrs: { "stop-color": "#A6DC48" } }),
                _c("stop", { attrs: { offset: "1", "stop-color": "#14A40E" } }),
              ],
              1
            ),
            _c("clipPath", { attrs: { id: "clip0_417_2683" } }, [
              _c("rect", {
                attrs: {
                  width: "98",
                  height: "48.3123",
                  fill: "white",
                  transform: "translate(0 0.535034)",
                },
              }),
            ]),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }